import React from 'react';
import LandingHome from '../Components/LandingHome';

const LandingPage = () => {
  return (
    <div>
      <LandingHome/>
    </div>
  );
};

export default LandingPage;
