import React from 'react';
import SignIn from '../Components/SignIn/SignIn';

const SignInPage = () => {
  return (
    <div>
      <SignIn />
    </div>
  );
};


export default SignInPage;
