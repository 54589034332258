import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { lightTheme } from "./styles/theme";
import { DataProvider } from "./Contexts/DataContext";
import LandingPage from "./Pages/LandingPage";
import BotPage from "./Pages/BotPage";
import SignInPage from "./Pages/SignInPage";
import SignUpPage from "./Pages/SignUpPage";
import ProfileMenu from "./Components/ProfileMenu";
import VoiceCall from "./Pages/VoiceCall";
import OutboundCall from "./Pages/OutBondCall";

const PrivateRoute = ({ children }) => {
  const user = localStorage.getItem('user');
  const token = localStorage.getItem('token');

  if (user && token) {
    return children;
  }
  return <Navigate to="/" replace />;
};

const PublicRoute = ({ children }) => {
  const user = localStorage.getItem('user');
  const token = localStorage.getItem('token');

  if (user && token) {
    return <Navigate to="/landing" replace />;
  }
  return children;
};

// New component for oEmbed-like functionality
const EmbeddableOutboundCall = () => {
  return (
    <div>
      <OutboundCall />
      <script type="application/json" id="oembed-data">
        {JSON.stringify({
          version: "1.0",
          type: "rich",
          provider_name: "Dynapt AI",
          provider_url: "https://ai.dynapt.com",
          title: "Dynapt AI Outbound Call",
          author_name: "Dynapt AI",
          author_url: "https://ai.dynapt.com",
          html: "<div id='dynapt-ai-outbound-call'></div><script src='https://ai.dynapt.com/embed.js'></script>",
          width: 600,
          height: 400
        })}
      </script>
    </div>
  );
};

const App = () => {
  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      <DataProvider>
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <PublicRoute>
                  <OutboundCall />
                </PublicRoute>
              }
            />
            <Route
              path="/embed"
              element={<EmbeddableOutboundCall />}
            />
          </Routes>
        </Router>
      </DataProvider>
    </ThemeProvider>
  );
};

export default App;
