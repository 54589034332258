import React, { useState, useEffect } from "react";
import Vapi from "@vapi-ai/web";
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
  Autocomplete,
  InputAdornment,
} from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import CallEndIcon from "@mui/icons-material/CallEnd";
import ProfileMenu from "../Components/ProfileMenu";
import CompanyLogo from "../Components/CompanyLogo";
import svg from "../Assets/66ce81f14d5bc2c55a9ff1f3_Group 1000002234.svg";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../styles/botPage.css";
import { countries } from "../utils/Countries";
import { keyframes } from '@mui/system';
import "../styles/outbondcall.css";
import { TypeAnimation } from 'react-type-animation';

const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;
const ASSISTANT_ID = process.env.REACT_APP_ASSISTANT_ID;
const PHONE_NUMBER_ID = process.env.REACT_APP_PHONE_NUMBER_ID;
const API_KEY = process.env.REACT_APP_PRIVATE_KEY;

const vapi = new Vapi(PUBLIC_KEY);

const OutboundCall = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(countries.find(country => country.code === "US"));
  const [connecting, setConnecting] = useState(false);
  const [connected, setConnected] = useState(false);
  const [error, setError] = useState("");
  const [isCallInitiated, setIsCallInitiated] = useState(false);

  const startCall = async () => {
    if (!selectedCountry || !phoneNumber) {
      setError("Please enter a valid phone number.");
      return;
    }

    setConnecting(true);
    setError("");
    setIsCallInitiated(false);

    const fullPhoneNumber = `+${selectedCountry.phone}${phoneNumber}`;

    const options = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${API_KEY}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        assistantId: ASSISTANT_ID,
        phoneNumberId: PHONE_NUMBER_ID,
        customer: {
          number: fullPhoneNumber,
        },
      }),
    };

    try {
      const response = await fetch("https://api.vapi.ai/call/phone", options);
      const data = await response.json();

      if (response.ok) {
        console.log("Call initiated successfully:", data);
        setIsCallInitiated(true);
      } else {
        throw new Error(data.message || "Failed to start the call");
      }
    } catch (error) {
      console.error("Error starting outbound call:", error);
      setError(error.message || "Failed to start the call. Please try again.");
    } finally {
      setConnecting(false);
    }
  };

  // Define keyframes for animations
  const fadeIn = keyframes`
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
  `;

  const pulse = keyframes`
    0% { box-shadow: 0 0 0 0 rgba(107, 91, 255, 0.7); }
    70% { box-shadow: 0 0 0 10px rgba(107, 91, 255, 0); }
    100% { box-shadow: 0 0 0 0 rgba(107, 91, 255, 0); }
  `;

  const fullText = "Hey there, I'm Dynapt's AI Chatbot! I can make millions of phone calls — 24/7 — in any voice and for any use case. I think I sound like a real human, but you can be the judge of that. Just enter your number, and I'll give you a call";

  return (
    <Box id="voice-bot-main-container"
      sx={{
        animation: `${fadeIn} 0.5s ease-out`,
      }}
    >
     
      <Box
        id="voice-bot-container"
        sx={{
          flexDirection: "column",
          alignItems: "center",
          gap: 3,
          padding: { xs: 2, md: 4 },
          margin: "0 auto",
          width: "100%",
          maxWidth: "1200px",
          // backgroundColor: "rgba(255, 255, 255, 0.05)",
          // backgroundColor:'#BABFBE',
          borderRadius: "10px",
          boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
          border: "1px solid rgba(255, 255, 255, 0.1)",
          transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
          animation: `${fadeIn} 0.5s ease-out 0.2s both`,
        }}
      >
      <section id="hero" className="section_hero">
        <div className="padding-global">
          <div className="container-large">
            <div className="phone_wrap">
              <div className="phone_phone-wrap" style={{opacity: 1}}>
                <div className="phone_bg-combined">
                  <img src="https://cdn.prod.website-files.com/66cddff3a7bb08f0bc015747/66d20f3fda5eca066fc237e3_Phone%20Left.svg" loading="lazy" alt="" className="phone_bg-image"/>
                  <img src="https://cdn.prod.website-files.com/66cddff3a7bb08f0bc015747/66d20f3fda9ae3c0ae4bacb0_Phone%20Middle.svg" loading="lazy" alt="" className="phone_bg-image"/>
                  <img src="https://cdn.prod.website-files.com/66cddff3a7bb08f0bc015747/66d20f3f80240145e8fb02f3_Phone%20Right.svg" loading="lazy" alt="" className="phone_bg-image"/>
                </div>
                <div className="phone_text-wrap">
                  <div className="phone_text-head padding-custom1">
                    <div className="section-number margin-0 padding-0 text-color-alternate">
                      <div className="button-dot is-purple is-big"></div>
                      <div>Dynapt AI</div>
                    </div>
                    <div>{new Date().toLocaleTimeString([],{ hour: '2-digit', minute: '2-digit', hour12: true })}</div>
                    {/* <div>{new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</div> */}
                  </div>
                  <div className="phone_text-anim">
                    <TypeAnimation
                      sequence={[fullText]}
                      wrapper="p"
                      speed={60}
                      style={{ fontSize: '1.25rem', display: 'inline-block' }}
                      repeat={2}
                      // cursor={true}
                      className="phone_text"
                    />
                  </div>
                </div>
                <img src={svg} loading="lazy" alt="" className="phone_arrow"/>
                <div id="email-form" className="phone_form-wrap w-form">
                  {!isCallInitiated ? (
                    <form onSubmit={(e) => { e.preventDefault(); startCall(); }} className="form_form">
                      <div className="w-embed">
                        <style>
                          {`
                            .form_field-wrapper {
                              position: relative;
                              overflow: hidden;
                              display: flex;
                              align-items: center;
                              justify-content: center;
                              padding: 2px;
                            }

                            .form_field-wrapper .form_field {
                              position: relative;
                              z-index: 1;
                              width: 100%;
                            }

                            .form_field-wrapper::before {
                              content: "";
                              display: block;
                              background: conic-gradient(from 180deg at 50% 50%, #32b44a 0deg, #3adb33 180deg, #FFFFFF 360deg);
                              height: 38rem;
                              width: 38rem;
                              position: absolute;
                              animation: rotate 16s linear infinite;
                              z-index: 0;
                            }

                            @keyframes rotate {
                              from {
                                transform: rotate(0);
                              }
                              to {
                                transform: rotate(360deg);
                              }
                            }
                          `}
                        </style>
                      </div>
                      <div className="form_field-wrapper">
                        <div className="form_field">
                          <Autocomplete
                            options={countries}
                            getOptionLabel={(option) => `+${option.phone}`}
                            renderOption={(props, option) => (
                              <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                                <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`} alt="" />
                                +{option.phone}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Country"
                                sx={{
                                  width: "120px",
                                  height: "100%",
                                  "& .MuiInputBase-input": { padding: "10px", color: "#fff", fontSize: "1rem" },
                                  "& .MuiInputBase-root": { border: "none", height: "66px" },
                                  "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                                  "& .MuiSvgIcon-root": { color: "gray" },
                                  // Add this line to change the text color to white
                                  "& .MuiInputBase-input.MuiAutocomplete-input": { color: "#fff" },
                                }}
                              />
                            )}
                            value={selectedCountry}
                            onChange={(event, newValue) => {}} // Do nothing on change
                            disabled={true} // Disable the dropdown
                            sx={{
                              backgroundColor: "#1d1d1d",
                              "& .MuiAutocomplete-inputRoot": { border: "none" },
                              // Add these lines to ensure the text is white even when disabled
                              "& .Mui-disabled": { color: "#fff !important" },
                              "& .MuiInputBase-input.Mui-disabled": { WebkitTextFillColor: "#fff" },
                            }}
                          />
                          <input
                            className="form_input w-input"
                            type="tel"
                            placeholder="Enter your number"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            required
                          />
                          <Button
                            type="submit"
                            className="button is-form-submit w-button"
                            // disabled={connecting}
                            sx={{
                              backgroundColor: "#32b44a",
                              color: "#fff",
                              "&:hover": { backgroundColor: "#295c33" },
                              // animation: connecting && !isCallInitiated ? `${pulse} 1.5s infinite` : 'none',
                              color: "#fff",
                            }}
                          >
                            {connecting && !isCallInitiated ? "PLEASE WAIT" : "CALL ME"}
                          </Button>
                        </div>
                      </div>
                    </form>
                  ) : (
                    <div className="padding-xsmall form w-form-done">
                      <div className="text-size-regular text-size-tiny">You are now receiving a call from Dynapt AI</div>
                    </div>
                  )}
                  {/* <div className="w-form-fail">
                    <div>Oops! Something went wrong while submitting the form.</div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      </Box>
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError("")}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setError("")}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Box>
  );
};

export default OutboundCall;
