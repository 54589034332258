import React from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";

const SuggestionCard = ({ onSuggestionClick, suggestions }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        marginBottom: 2,
        height: "450px",
        justifyContent: "center", // Center content vertically
      }}
    >
      <Typography
        variant="body2"
          sx={{
            textAlign: "center",
            color: "#ffffff",
            fontSize: "16px",
            fontWeight: "bold",
            // background: "linear-gradient(45deg, #00ffcc, #ffffffb5)",
            background: "linear-gradient(45deg, #1A2552, #3221f3)",

            backgroundClip: "text",
            textFillColor: "transparent",
          }}
      >
        Suggestions
      </Typography>

      {suggestions?.map((suggestion, index) => (
        <Card
          key={index}
          sx={{
            height: "fit-content",
            cursor: "pointer",
            color: "#ffffff",
            display: "flex", // Add flex display
            alignItems: "center", // Center content vertically within the card
            backgroundColor: "rgba(255, 255, 255, 0.05)",
            background: "linear-gradient(45deg, #1A2552, #1a2552)",

            backdropFilter: "blur(15px)",
            borderRadius: "10px",
            boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
            border: "1px solid rgba(255, 255, 255, 0.1)",
            transition:
              "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
            "&:hover": {
              transform: "translateY(-10px)",
              boxShadow: "0 8px 16px rgba(0, 0, 0, 0.3)",
              backgroundColor: "inherit",
            },
          }}
          onClick={() => onSuggestionClick(suggestion)}
        >
          <CardContent sx={{ width: "100%", textAlign: "center", padding: "16px", "&:last-child": { paddingBottom: "16px" } }}>
            <Typography variant="body2" sx={{
            color: "text.primary",
                    fontWeight: "bold",
                    // background: "linear-gradient(45deg, #00ffcc, #ffffffb5)",
                    background: "linear-gradient(45deg, #ffffff, #ffffff)",

                    backgroundClip: "text",
                    textFillColor: "transparent",
            }}>{suggestion}</Typography>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export default SuggestionCard;
