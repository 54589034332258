import React, { useEffect, useRef } from "react";
import { Box, Typography, Avatar, Button } from "@mui/material";
import { styled } from "@mui/system";
import "../styles/botPage.css";
import DownloadIcon from "@mui/icons-material/Download";
import EmailIcon from "@mui/icons-material/Email";

const ChatContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  height: "400px",
  overflowY: "auto",
  borderRadius: "10px",
  scrollbarWidth: "none",
  "&::-webkit-scrollbar": {
    display: "none",
  },

});

const MessageContainer = styled(Box)(({ role }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: "10px",
  flexDirection: role === "user" ? "row-reverse" : "row",
}));

const MessageBubble = styled(Box)(({ role }) => ({
  maxWidth: "70%",
  padding: "10px 15px",
  borderRadius: "15px",
  // background: "linear-gradient(45deg, #1A2552, #1a2552)",
  backgroundColor: role === "user" ? "#ffffff" : "#1a2552",
  border: role === "user" ? "1px solid #1a2552" : "1px solid #ffffff",
  marginLeft: role === "user" ? "10px" : "5px",
  marginRight: role === "user" ? "5px" : "10px",
  boxShadow: "0px 1px 5px rgba(0,0,0,0.2)",
  wordBreak: "break-word",
  backdropFilter: "blur(15px)",
}));

const GradientText = styled(Typography)(({ role }) => ({
  fontWeight: "bold",
  background: role === "user"
    ? "#1a2552"
    : "#ffffff",
  backgroundClip: "text",
  textFillColor: "transparent",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
}));

const LiveTranscript = ({ conversation, downloadTranscript, hasTranscript, isConnected }) => {
  const chatEndRef = useRef(null);

  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [conversation]);

  return (
    <>
      <Box>
        <Typography
          variant="body2"
          sx={{
            textAlign: "center",
            color: "#ffffff",
            fontSize: "16px",
            marginBottom: "20px",
            fontWeight: "bold",
            // background: "linear-gradient(45deg, #00ffcc, #ffffffb5)",
            background: "linear-gradient(45deg, #1A2552, #1a2552)",

            backgroundClip: "text",
            textFillColor: "transparent",
          }}
        >
          Live Transcript
        </Typography>
        <ChatContainer>
          {conversation.map((message, index) => (
            <MessageContainer key={index} role={message.role}>
              <Avatar
                alt={message.role}
                src={
                  message.role === "user"
                    ? "https://as1.ftcdn.net/v2/jpg/04/23/59/74/1000_F_423597477_AKCjGMtevfCi9XJG0M8jter97kG466y7.jpg"
                    : "https://img.freepik.com/premium-photo/woman-robot-with-mobile-her-hand_795294-16.jpg"
                }
              />
              <MessageBubble role={message.role}>
                <GradientText variant="body2" role={message.role}>
                  {message.content}
                </GradientText>
              </MessageBubble>
            </MessageContainer>
          ))}
          <div ref={chatEndRef} style={{ float: "left", clear: "both" }} />
        </ChatContainer>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            marginTop: "20px",
          }}
        >
          <Button
            variant="contained"
            startIcon={<DownloadIcon />}
            onClick={downloadTranscript}
            disabled={!hasTranscript || !isConnected}
            sx={{
              backgroundColor: "#1A2552",
              color: "#FFFFFF",
              "&:hover": {
                backgroundColor: "#2A3562",
              },
              "&:disabled": {
                backgroundColor: "rgba(26, 37, 82, 0.5)",
                color: "rgba(255, 255, 255, 0.5)",
              },
            }}
          >
            Download Transcript
          </Button>
          <Button
            variant="outlined"
            startIcon={<EmailIcon />}
            disabled={!hasTranscript || !isConnected}
            sx={{
              backgroundColor: "#FFFFFF",
              color: "#1A2552",
              borderColor: "#1A2552",
              "&:hover": {
                backgroundColor: "rgba(26, 37, 82, 0.1)",
                borderColor: "#1A2552",
              },
              "&:disabled": {
                backgroundColor: "#FFFFFF",
                color: "rgba(26, 37, 82, 0.5)",
                borderColor: "rgba(26, 37, 82, 0.5)",
              },
            }}
          >
            Send As Email
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default LiveTranscript;
